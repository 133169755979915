.p-0 {
  padding: 0 !important;
}

.p-15 {
  padding: 15px;
}

.pl-0 {
  padding-left: 0 !important;
}

.pl-5 {
  padding-left: 5px !important;
}

.pl-10 {
  padding-left: 10px !important;
}

.pl-12 {
  padding-left: 12px !important;
}

.pl-20 {
  padding-left: 20px;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 1px;
}

.pt-2 {
  padding-top: 2px;
}

.pt-5 {
  padding-top: 5px;
}

.pt-10 {
  padding-top: 10px !important;
}

.pt-12 {
  padding-top: 12px !important;
}

.pt-15 {
  padding-top: 15px !important;
}

.pt-16 {
  padding-top: 16px !important;
}

.pt-20 {
  padding-top: 20px;
}

.pt-25 {
  padding-top: 25px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-75 {
  padding-top: 75px;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-2 {
  padding-bottom: 2px;
}

.pb-5 {
  padding-bottom: 5px;
}

.pb-10 {
  padding-bottom: 10px !important;
}

.pb-15 {
  padding-bottom: 15px !important;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-25 {
  padding-bottom: 25px;
}

.pb-40 {
  padding-bottom: 40px !important;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-75 {
  padding-bottom: 75px;
}

.pb-150 {
  padding-bottom: 200px;
}

.pr-0 {
  padding-right: 0 !important;
}

.pr-10 {
  padding-right: 10px !important;
}

.pr-12 {
  padding-right: 12px !important;
}

.pr-20 {
  padding-right: 20px !important;
}

.pr-45 {
  padding-right: 45px;
}
