@use '@angular/material' as mat;

@use 'variables' as v;
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: var(--mat-sys-surface-variant);
  border-radius: 12px;
  margin: 4px 0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--mat-sys-tertiary);
  border-radius: 12px;
  border: 3px solid transparent;
  background-clip: content-box;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #444444;
  border-radius: 12px;
  border: 3px solid transparent;
  background-clip: content-box;
}

// h1,
// h2,
// h3,
// h4,
// h5,
// h6,
// .mat-caption,
// .overline,
// p {
//   color: v.$light-text;
//   margin: 0 !important;
// }

// h4 {
//   margin: 0 !important;
// }

// h5 {
//   font-family: v.$font-family !important;
//   font-size: 1.25rem !important;
//   font-weight: 700 !important;
//   line-height: 120% !important;
//   letter-spacing: 0.0125rem !important;
// }

// h6 {
//   font-family: v.$font-family !important;
//   font-size: 1.125rem !important;
//   font-weight: 500 !important;
//   line-height: 118% !important;
//   letter-spacing: 0.01125rem !important;
// }

// .btn {
//   border-radius: 6px !important;
//   font-size: 16px !important;
//   padding: 8px 15px !important;
// }

// .page-container {
//   margin-top: 58.19px;
// }

// .form-field {
//   display: flex;
//   flex-direction: column;
//   gap: 4px;
// }

// .view-header-container {
//   background-color: #fafafa;
//   border-bottom: 5px solid v.$secondary;
//   height: 55px;
//   position: fixed;
//   width: 100vw;
//   z-index: 1000;
// }

// .view-header {
//   align-items: center;
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
//   margin: 12px 20px;

//   button {
//     border-color: v.$primary;
//     border-radius: 6px !important;
//     color: v.$primary !important;
//     height: 31px !important;
//     min-width: unset !important;
//     width: 60px !important;
//   }
// }

// .primary-solid-button {
//   background-color: #0e233c !important;
//   color: #fafafa !important;
// }

// .view-header-no-edit {
//   margin: 16px 20px !important;
// }

// .data-container {
//   margin: 25px 20px 75px 20px;
// }

// .data-container-sm {
//   margin: 25px 20px;
// }

// .start-page-data {
//   margin-top: 75px !important;
// }

// .start-page-data-lg {
//   margin-top: 100px !important;
// }

// .next-button-container {
//   bottom: 0;
//   display: flex;
//   justify-content: flex-end;
//   padding: 20px;
//   position: fixed;
//   width: calc(100vw - 40px);
// }

// .next-button {
//   border-radius: 6px !important;
//   color: #fff !important;
//   height: 35px !important;
//   min-width: unset !important;
// }

// .nav-button-container {
//   align-items: center;
//   background-color: #fafafa;
//   bottom: 0;
//   display: flex;
//   justify-content: space-between;
//   padding: 20px;
//   position: fixed;
//   width: calc(100vw - 40px);
// }

// .nav-button-container-not-mobile {
//   align-items: center;
//   background-color: #fafafa;
//   display: flex;
//   justify-content: flex-end;
//   padding: 20px;
//   width: calc(100vw - 40px);
// }

// .back-button {
//   border-color: v.$primary !important;
//   border-radius: 6px !important;
//   height: 35px !important;
//   min-width: unset !important;
// }

// .edit-button {
//   border: 1px solid v.$primary !important;
// }

// .add-button {
//   border-color: v.$primary !important;
//   border-radius: 6px !important;
//   height: 38px !important;
//   min-width: unset !important;
// }

// .remove-button {
//   border-color: v.$warn !important;
//   border-radius: 6px !important;
//   color: v.$warn !important;
//   height: 34px !important;
//   min-width: unset !important;
//   padding: 10px !important;
// }

// .cancel-button {
//   border-color: v.$primary !important;
//   border-radius: 6px !important;
//   height: 35px !important;
//   margin-right: 10px;
//   min-width: unset !important;
// }

// .save-button {
//   border-radius: 6px !important;
//   color: #fff !important;
//   height: 35px !important;
//   min-width: unset !important;
//   background-color: #0e233c !important;
// }

// .divider {
//   border: solid 1px v.$secondary;
// }

// .loading {
//   height: 30vh;
//   display: flex;
//   justify-content: center;
// }
