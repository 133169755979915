.w-1p {
  width: 1%;
}

.w-5p {
  width: 5%;
}

.w-10p {
  width: 10%;
}

.w-12p {
  width: 12%;
}

.w-15p {
  width: 15%;
}

.w-17p {
  width: 17%;
}

.w-20p {
  width: 20%;
}

.w-21p {
  width: 21%;
}

.w-45p {
  width: 45%;
}

.w-50p {
  width: 50%;
}

.w-60p {
  width: 60%;
}

.w-80p {
  width: 80%;
}

.w-100p {
  width: 100%;
}

.w-20x {
  width: 20px;
}

.w-50x {
  width: 50px;
}

.w-130x {
  width: 130px;
}

.w-160x {
  width: 160px;
}

.w-200x {
  width: 200px;
}

.w-260x {
  width: 260px;
}

.w-300x {
  width: 300px;
}

.w-400x {
  width: 400px;
}

.w-900x {
  width: 900px;
}

.w-1000x {
  width: 1000px;
}

.w-1100x {
  width: 1100px;
}
