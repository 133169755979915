@use '@angular/material' as mat;
@use '_theme-colors.scss' as my-theme;
@use 'partials';

html {
  color-scheme: light dark;
  @include mat.theme(
    (
      color: (
        primary: my-theme.$primary-palette,
        tertiary: my-theme.$tertiary-palette,
      ),
      typography: Montserrat,
    )
  );
}

.accent {
  @include mat.theme(
    (
      color: my-theme.$secondary-palette,
    )
  );
}

.warn {
  @include mat.theme(
    (
      color: my-theme.$error-palette,
    )
  );
}

body {
  height: 100%;
}

body {
  background-color: var(--mat-sys-background);
  color: var(--mat-sys-on-background);
  margin: 0;
  font-family: Montserrat, Verdana, Geneva, Tahoma, sans-serif;
}

:root {
  @include mat.dialog-overrides(
    (
      container-shape: var(--mat-sys-corner-none),
    )
  );

  @include mat.icon-overrides(
    (
      color: var(--mat-sys-on-surface),
    )
  );
}

h4 {
  margin: 0;
}

p {
  margin: 0;
}

.primary-outline-btn {
  @include mat.button-overrides(
    (
      outlined-outline-color: var(--mat-sys-primary),
      outlined-label-text-color: var(--mat-sys-primary),
      outlined-container-shape: 6px,
      outlined-container-height: 29px,
      outlined-horizontal-padding: 14px,
      outlined-label-text-weight: 400,
      outlined-label-text-size: 1rem,
    )
  );
}
