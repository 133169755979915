.h-100p {
  height: 100%;
}

.h-80p {
  height: 80%;
}

.h-50p {
  height: 50%;
}

.height-inherit {
  height: inherit;
}

.h-20x {
  height: 20px;
}

.h-40x {
  height: 40px;
}

.h-190x {
  height: 190px;
}
