@use 'partials/_flex';
@use 'partials/_font';
@use 'partials/height';
@use 'partials/_margin';
@use 'partials/_padding';
@use 'partials/_width';
@use 'partials/_app-specific';
@use 'partials/_buttons';

.inline {
  display: inline !important;
}

.cursor {
  cursor: pointer;
}

.disable {
  pointer-events: none;
}
