.m-0 {
  margin: 0;
}

.m-10 {
  margin: 10px !important;
}

.m-15 {
  margin: 15px !important;
}

.m-20 {
  margin: 20px !important;
}

.ml-0 {
  margin-left: 0;
}

.ml-5 {
  margin-left: 5px;
}

.ml-10 {
  margin-left: 10px !important;
}

.ml-12 {
  margin-left: 12px;
}

.ml-15 {
  margin-left: 15px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-25 {
  margin-left: 25px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-40 {
  margin-left: 40px;
}

.mt-auto {
  margin-top: auto;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-2 {
  margin-top: 2px;
}

.mt-4 {
  margin-top: 4px !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mt-8 {
  margin-top: 8px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-22 {
  margin-top: 22px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-90 {
  margin-top: 90px;
}

.mt-n10 {
  margin-top: -10px !important;
}

.mr-0 {
  margin-right: 0 !important;
}

.mr-2 {
  margin-right: 2px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-9 {
  margin-right: 9px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px !important;
}

.mr-24 {
  margin-right: 24px;
}

.mr-25 {
  margin-right: 25px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-13 {
  margin-bottom: 13px;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-29 {
  margin-bottom: 29px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mbs-1 {
  margin-block-start: 1em;
}

.mbe-1 {
  margin-block-end: 1em;
}

.mi-0 {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
